import Axios from "axios";
import { Link, graphql } from "gatsby";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Constants } from "../../@types/Constants";
import { ISearchPayload } from "../../@types/ISearchPayload";
import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import FeaturesTabs from "../../components/common/FeaturesTabs/FeaturesTabs";
import HotelRooms from "../../components/common/HotelRooms/HotelRooms";
import HotelTopAlert from "../../components/common/HotelTopAlert/HotelTopAlert";
import PhotosGallery from "../../components/common/PhotosGallery/PhotosGallery";
import PointsOfInterest from "../../components/common/PointsOfInterest/PointsOfInterest";
import VideoPlayer from "../../components/common/VideoPlayer/VideoPlayer";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import Subtitle from "../../components/global/SubTitle/SubTitle";
import Title2 from "../../components/global/Title2/Title2";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useMobileDetect from "../../hooks/useMobileDetect";
import { useWebFramed } from "../../hooks/useWebFramed";
import { Room } from "../../models/room";
import { brandSelector } from "../../redux/slices/Brand/brand";
import { searchSelector, setSearch } from "../../redux/slices/Search/search";
import { guestServiceProvider } from "../../services/ServiceProvider";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { getSearchPayloadStayDates, gotoTab } from "../../utils/helpers";
import { getParagraph } from "../../utils/paragraphHelpers";
import { Storage } from "../../utils/storage";
import {
  AddressBar,
  AddressBarContainer,
  AgentLink,
  AnchorMenus,
  Banner,
  HotelAwardsSection,
  HotelPageBreadCrumb,
  HotelTitle,
  MobileMenuContainer,
  MobilelAddressBar,
  StickyContainer,
  StyledContainer,
} from "./HotelPageHelpers";
import { IHotelPageProps } from "./HotelPageProps";
import About from "./helperComponents/About/About";

const HotelPage = (props: IHotelPageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const searchData = useAppSelector(searchSelector);
  const isMobileOnly = useMobileDetect();
  const brand = useAppSelector(brandSelector);
  const _hotel = props.data.hotel;
  const _queriedLinks = props.data.allLocationCity.nodes;
  const unlockBannerShow = props.data.page
    ? props.data.page.field_show_unlock_banner
    : false;
  const _location = props.location;
  const _featuredOffers = _hotel.relationships.offers
    ? getParagraph(_hotel.relationships.offers, { crsCode: _hotel.crs_code })
    : null;
  const _fieldBody = _hotel.relationships.field_hotel_body
    ? getParagraph(_hotel.relationships.field_hotel_body, {
        crsCode: _hotel.crs_code,
      })
    : null;

  const _gallery = Array.isArray(props.pageContext.galleryImages)
    ? props.pageContext.galleryImages
    : [];
  const hotelInfoBrandBarBgColor =
    _hotel.relationships.brand_id?.field_brandbars_bg_color?.color;
  const hotelInfoBrandBarFontColor =
    _hotel.relationships.brand_id?.field_brandbars_fore_color?.color;
  const hotelInfoUrgBgColor =
    _hotel.relationships.brand_id?.field_urgency_msg_bg_color?.color;
  const hotelInfoUrgFontColor =
    _hotel.relationships.brand_id?.field_urgency_msg_fore_color?.color;
  const hotelInfoActiveColor =
    _hotel.relationships.brand_id?.field_brand_stripe_color?.color;
  const countryCode = _hotel.address?.country_code;
  const _field_video_url = _hotel.field_video_url;
  const pageTopAlert = _hotel.relationships.field_page_message;
  const hotelAwards = _hotel.relationships.hotelAwards
    ? _hotel.relationships.hotelAwards
    : [];
  const hotelDefaultLOS = _hotel.field_default_length_of_stay;
  const hotelDefaultLD = _hotel.field_default_lead_days;
  const hotelDefaultCheckinDate = _hotel.field_lead_start_date;
  const hotelDefaultAdults = _hotel.field_default_adults;
  const [points, setPoints] = useState([]);
  const [showAnchor, setShowAnchor] = useState(false);
  const [loadingPoints, setLoadingPoints] = useState(false);
  const [distance, setDistance] = useState(10);
  const [offerClicked, _setOfferClicked] = useState(false);
  const [_ShowOfferMessage, setShowOfferMessage] = useState(false);
  const [_message, setMessage] = useState({});
  const [tabId, setTabId] = useState<string | null>(null);
  const [checkDeepLink, setCheckDeepLink] = useState(false);
  const hotelParagraphs = _hotel.relationships.field_sections.map(
    (paragraph) => {
      return getParagraph(paragraph, {
        backgroundColor: hotelInfoBrandBarBgColor,
      });
    }
  );
  const [showModalFeature, setShowModalFeature] = useState(false);
  const [modalContent, setModalContent] = useState("");

  // const [, setSearchInitialized] = useState(false);
  const specialOffers = _hotel.relationships.offers;
  // const points="";
  // const loadingPoints=""

  const features = _hotel.features;
  const renderedFeatures = [];
  let aboutText = "";
  const logo = brand?.relationships?.field_logo?.publicUrl || "";
  if (features !== null && features.length) {
    features.forEach((feature: { tab_title: string; description: string }) => {
      const tabKey = feature.tab_title.toLowerCase().trim();
      if (tabKey === "about") {
        aboutText = feature.description;
      } else {
        renderedFeatures.push(feature);
      }
    });
  }

  const _address = _hotel.address || {};
  const _addressString = _address
    ? `${_address.address_line1}+${_address.locality}+${_address.administrative_area}+${_address.postal_code}`
    : "";
  const _addressUrl = _addressString.replace(/\s+/g, "+");
  const _directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${_addressUrl}`;
  const _coordinates = _hotel.lat_lon;
  const _description = _hotel.description;
  let _metaDescription = _hotel.field_hotel_meta_description;
  const isTargetcookiesActive =
    typeof window !== "undefined" &&
    window?.OnetrustActiveGroups?.includes("C0004")
      ? true
      : false;
  const BreadcrumbDetails = { _hotel, _queriedLinks, _location };
  if (!_metaDescription) {
    if (_metaDescription && _description && _description.length > 160) {
      const _subDesc = _description.substring(0, 160).split(".");
      _subDesc.pop();
      _metaDescription = _subDesc.join(".") + ".";
    } else {
      _metaDescription = _description;
    }
  }
  // Build anchor links menu.
  const _anchorLinks = [{ label: "Overview", anchor: "overview" }];
  _anchorLinks.push({ label: "Rooms", anchor: "rooms" });
  if (_gallery?.length && !loadingPoints) {
    _anchorLinks.push({ label: "Gallery", anchor: "gallery" });
  }
  if (specialOffers && !loadingPoints) {
    _anchorLinks.push({ label: "Offers", anchor: "offers" });
  }
  if (points !== null && points?.length && !loadingPoints) {
    _anchorLinks.push({ label: "What's Nearby", anchor: "thingstodo" });
  }
  if (features !== null && features.length && !loadingPoints) {
    _anchorLinks.push({ label: "Hotel Details", anchor: "hoteldetails" });
  }

  const brandBias = JSON.parse(
    Storage.GetLocalStorageValue(Constants.BRAND_BIAS_KEY)
  );
  if (brandBias?.brand_code !== _hotel.relationships.brand_id.brand_code) {
    Storage.ClearLocalStorageValue(Constants.BRAND_BIAS_KEY);
  }

  const handleClickTab = (_id: string) => {
    setTabId(_id);
    gotoTab(_id, isMobileOnly);
    // const targetElm = document.getElementById(_id);
    // if (targetElm) {
    //   const elementRect = targetElm.getBoundingClientRect();
    //   const absoluteElementTop = elementRect.top + window.pageYOffset;
    //   const middle = absoluteElementTop;// - (window.innerHeight / 2);
    //   window.scrollTo(0, middle - 120);
    //   // targetElm.scrollIntoView({block: "nearest"});
    // }
  };

  const handleSetDistance = (newDistance: any) => {
    setDistance(newDistance);
  };

  // Fetch points of interest.
  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel && _coordinates !== null) {
        setLoadingPoints(true);
        let url = `/location-proximity?coordinates[value]=${distance}`;
        url += `&coordinates[source_configuration][origin][lat]=${_coordinates.lat}&coordinates[source_configuration][origin][lon]=${_coordinates.lon}`;
        url += "&type[0]=point_of_interest&type[1]=airport";
        // const data = await coreGet(url, source);
        // if (!didCancel) {
        //   setPoints(data);
        //   setLoadingPoints(false);
        //   setShowAnchor(true)
        // }
        //Use Location Proximity Response
        const guestService = await guestServiceProvider();
        const response = await guestService.getLocationProximity(
          distance,
          _coordinates.lat,
          _coordinates.lon
        );

        if (response?.searchLocations?.results) {
          const filteredPoints = response.searchLocations.results.filter(
            (location) =>
              location.locationType === "point_of_interest" ||
              location.locationType === "airport"
          );
          setPoints(filteredPoints);
          setLoadingPoints(false);
          setShowAnchor(true);
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, [distance]);

  // Initialize Search.
  useEffect(() => {
    try {
      addPageTypeInGTMDataLayer("hotel-detail");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  useEffect(() => {
    try {
      const path = _location?.pathname && _location.pathname.split("/");
      const pathLastPart = path ? path.pop() || path.pop() : ""; //twice pop to handle if trailing slash is there
      if (pathLastPart == "offers") {
        handleClickTab("Offers");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  useEffect(() => {
    try {
      if (window.location.hash === "#rooms") {
        handleClickTab("rooms");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);
  // Parse and render Modal
  useEffect(() => {
    const modals = document.querySelectorAll("[data-modal]");

    const handleOpenModal = (event: Event) => {
      event.preventDefault();
      let anchor = event.target as HTMLAnchorElement;

      if (anchor.tagName !== "A") {
        anchor = anchor.parentElement as HTMLAnchorElement;
      }

      setModalContent(anchor.href);
      setShowModalFeature(true);
    };

    modals.forEach((modal) => modal.addEventListener("click", handleOpenModal));

    return () =>
      modals.forEach((modal) =>
        modal.removeEventListener("click", handleOpenModal)
      );
  }, []);

  //to handle the redirect for hotels with badpath, redirection is added to handle canonical tags url
  useEffect(() => {
    const asteriskParam = props.params && props.params["*"];
    if (asteriskParam) {
      const pathURI = `/${asteriskParam}`;
      const newUrl = location.href.replace(pathURI, "");
      window && window.location.replace(newUrl);
    }
  }, []);

  useLayoutEffect(() => {
    const payload: ISearchPayload = {
      latitude: _coordinates.lat,
      longitude: _coordinates.lon,
      searchString: _hotel.name,
      searchType: "hotel",
      customUrl: _location?.pathname || searchData?.customUrl || "",
      searchTypeGapi: "lodging",
    };
    if (!_location?.state || _location.state?.defaultSearch) {
      window.history.replaceState(
        { ..._location.state, defaultSearch: false },
        ""
      ); // so that these default values are not used again on reload of page
      const searchString =
        typeof location?.search === "string" ? location.search : "";
      let searchParams: URLSearchParams;

      try {
        searchParams = new URLSearchParams(searchString);
      } catch (error) {
        console.error("Invalid search string:", searchString, error);
        searchParams = new URLSearchParams(); // Fallback to an empty URLSearchParams object
      }

      const queryParams: Record<string, string> =
        Object.fromEntries(searchParams);
      const queryParamsLowercase = Object.keys(queryParams).reduce(
        (qp, key) => {
          if (typeof key === "string" && typeof queryParams[key] === "string") {
            qp[key.toLowerCase()] = queryParams[key];
          }
          return qp;
        },
        {} as Record<string, string>
      );

      const hasCheckinCheckoutParams =
        "checkin" in queryParamsLowercase && "checkout" in queryParamsLowercase;
      if (!hasCheckinCheckoutParams) {
        const { formedCheckin, formedCheckout } = getSearchPayloadStayDates(
          null,
          null,
          hotelDefaultLOS,
          hotelDefaultLD,
          hotelDefaultCheckinDate
        );
        if (formedCheckin && formedCheckout) {
          payload.checkin = formedCheckin;
          payload.checkout = formedCheckout;
        }
      }
      if (hotelDefaultAdults) {
        payload.rooms = [new Room(hotelDefaultAdults)];
      }
    }
    dispatch(setSearch(payload));
    setCheckDeepLink(true);
  }, []);

  let errorTimer: any;
  const handleOnRoomsLoad = (
    offerAvailable: any,
    rate: any,
    discountType = "Promo code"
  ) => {
    clearTimeout(errorTimer);
    let message = {};
    // if(offerClicked) {
    if (!offerAvailable) {
      const discountText = offerClicked ? "Promo code" : "Discount";
      const errorMsg = (
        <>
          {discountText} <strong>{rate}</strong> is not available for the
          current search. Please modify your search criteria above. <br />
          <Link to="#Offers" className="text-underline">
            <u>Select another offer</u>
          </Link>{" "}
          or select a room below to proceed with your booking.
        </>
      );
      message = {
        type: "danger",
        text: errorMsg,
      };
    } else {
      const successMsg = (
        <>
          {discountType} <strong>{rate}</strong> has been applied. Select a room
          below to proceed with your booking.
        </>
      );
      message = {
        type: "success",
        text: successMsg,
      };
    }
    setShowOfferMessage(true);
    setMessage(message);
    const element = document.getElementById("offer-message");
    if (element) {
      element.scrollIntoView(true);
    }
    // auto hide after 15 sec
    errorTimer = setTimeout(() => {
      setShowOfferMessage(false);
      setMessage("");
    }, 10000);
    // }
  };

  return (
    <Layout
      brand={_hotel.relationships.brand_id}
      showLogo
      isHotelPage
      searchBreadCrumbStep="2"
      showMerchandisingBlock={countryCode == "US"}
    >
      <SEO
        title={_hotel.field_meta_t || _hotel.name}
        description={_metaDescription}
        includeLeaflet
        crsCode={_hotel.crs_code}
        city={_hotel?.address?.locality}
        brand={
          _hotel.relationships &&
          _hotel.relationships.brand_id &&
          _hotel.relationships.brand_id.name
        }
        koddiTitle="HotelPage"
        location={props.location}
      />
      <Helmet>
        <script src="https://www.speedrfp.com/irfps/js/widget/"></script>
      </Helmet>
      {checkDeepLink && (
        <DeepLinking
          location={_location}
          leadDays={hotelDefaultLD}
          lengthOfStay={hotelDefaultLOS}
          pageName={"Hotel"}
          HotelCode={_hotel.crs_code}
          hotelDefaultCheckinDate={hotelDefaultCheckinDate}
        />
      )}
      <Banner
        mobileImage={_hotel.relationships.mobileImage}
        desktopImage={_hotel.relationships.desktopImage}
      />
      {!isMobileOnly ? (
        <AddressBarContainer
          hotelInfoBgColor={hotelInfoBrandBarBgColor}
          hotelInfoFontColor={hotelInfoBrandBarFontColor}
        >
          <StyledContainer fluid="sm">
            <Row className="align-items-center">
              <Col lg={5} className={`col5 colBase1`}>
                <HotelTitle
                  id={"hotel-name"}
                  title={_hotel.name}
                  hotelInfoFontColor={hotelInfoBrandBarFontColor}
                ></HotelTitle>
              </Col>
              <Col
                lg={7}
                className={`col7 colBase1`}
                style={{ paddingLeft: "0" }}
              >
                {showAnchor ? (
                  <AnchorMenus
                    anchors={_anchorLinks}
                    tabId={tabId}
                    onClick={handleClickTab}
                    hotelInfoFontColor={hotelInfoBrandBarFontColor}
                    hotelInfoActiveColor={hotelInfoActiveColor}
                  />
                ) : (
                  <div className="anchorSpinner">
                    <Spinner animation="border" />
                  </div>
                )}
              </Col>
            </Row>
          </StyledContainer>
        </AddressBarContainer>
      ) : (
        <StickyContainer logo={logo} searchData={searchData}>
          <MobilelAddressBar
            hotelInfoBgColor={hotelInfoBrandBarBgColor}
            hotelInfoFontColor={hotelInfoBrandBarFontColor}
          >
            <Row>
              <Col className={`col5 colBase1`}>
                <HotelTitle
                  hotelInfoFontColor={hotelInfoBrandBarFontColor}
                  title={_hotel.name}
                ></HotelTitle>
                <AddressBar
                  hotelInfoFontColor={hotelInfoBrandBarFontColor}
                  address={_address}
                  directionLink={_directionsLink}
                  phone={_hotel.phone}
                />
              </Col>
            </Row>
          </MobilelAddressBar>
          <MobileMenuContainer hotelInfoBgColor={hotelInfoBrandBarBgColor}>
            <AnchorMenus
              anchors={_anchorLinks}
              tabId={tabId}
              onClick={handleClickTab}
              hotelInfoBgColor={hotelInfoBrandBarBgColor}
              hotelInfoActiveColor={hotelInfoActiveColor}
              hotelInfoFontColor={hotelInfoBrandBarFontColor}
            />
          </MobileMenuContainer>
        </StickyContainer>
      )}
      {pageTopAlert && pageTopAlert.length !== 0 ? (
        <HotelTopAlert
          message={pageTopAlert[0] ? pageTopAlert[0] : ""}
          hotelCode={_hotel.crs_code || ""}
        />
      ) : null}

      <StyledContainer fluid="sm">
        <>
          <a
            className="anchor-target visually-hidden"
            id="overview"
            aria-hidden={true}
          />
          <About hotel={_hotel} />
        </>
      </StyledContainer>
      {hotelParagraphs && hotelParagraphs.length > 0
        ? hotelParagraphs[0]
        : null}
      <StyledContainer
        fluid
        className="bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 position-relative"
      >
        <>
          <a
            className="anchor-target visually-hidden"
            id="rooms"
            aria-hidden={true}
          />
          <StyledContainer fluid="sm">
            <Row>
              <Col className="text-center text-lg-left hotelRooms">
                <Subtitle>Accommodations</Subtitle>
                <Title2 className="text-left">
                  {searchData.rooms && searchData.rooms.length > 1
                    ? "Select Your Rooms"
                    : "Select Your Room"}
                </Title2>
                {!isWebFramed && (
                  <AgentLink
                    search={searchData}
                    hotel_code={_hotel.crs_code}
                    chain_id={_hotel.crs_chain_id}
                  />
                )}
                {/* {searchInitialized && (
                 <HotelRooms hotel={hotel} onRoomsLoad={handleOnRoomsLoad} />
                 )} */}
                <HotelRooms
                  hotel={_hotel}
                  onRoomsLoad={handleOnRoomsLoad}
                  unlockBannerShow={unlockBannerShow}
                  hotelInfoUrgBgColor={hotelInfoUrgBgColor}
                  hotelInfoUrgFontColor={hotelInfoUrgFontColor}
                />
              </Col>
            </Row>
          </StyledContainer>
        </>
      </StyledContainer>
      {hotelAwards.length > 0 && <HotelAwardsSection awards={hotelAwards} />}
      {_field_video_url && (
        <StyledContainer fluid="sm">
          <Title2 className="explore-sonesta">Explore Sonesta</Title2>
        </StyledContainer>
      )}
      {_field_video_url && isTargetcookiesActive && (
        <StyledContainer fluid="sm">
          <VideoPlayer
            url={_field_video_url.url}
            width="100%"
            height="100%"
            aria-hidden="true"
          />
        </StyledContainer>
      )}

      {_gallery?.length && (
        <StyledContainer fluid="sm">
          <div>
            <a id="gallery" aria-hidden={true} />
            <PhotosGallery
              photos={_gallery}
              imgCountPerRow={1}
              subtitle="TAKE A VISUAL TOUR"
              newCarousel={true}
            />
          </div>
        </StyledContainer>
      )}
      {true && (_featuredOffers || specialOffers) && (
        <StyledContainer className="bg-light mt-5" fluid={true}>
          <a
            className="anchor-target visually-hidden"
            id="offers"
            aria-hidden={true}
          />
          <>{_featuredOffers}</>
        </StyledContainer>
      )}
      {_coordinates &&
      (distance <= 75 ||
        (distance === 100 && points !== null && points?.length)) ? (
        <StyledContainer
          fluid="sm"
          className="pt-4 pt-lg-5 pb-4 pb-lg-5 position-relative"
        >
          <>
            <a
              className="anchor-target visually-hidden"
              id="thingstodo"
              aria-hidden={true}
            />
            <Row>
              <Col className="text-center">
                <Subtitle>Explore the Area</Subtitle>
                <Title2 id="what-nearby">What&apos;s Nearby</Title2>
              </Col>
            </Row>
            <Row aria-hidden="true">
              <Col>
                <PointsOfInterest
                  latitude={_coordinates.lat}
                  longitude={_coordinates.lon}
                  points={points}
                  setDistance={handleSetDistance}
                  distance={distance}
                  isLoading={loadingPoints}
                  poiLocation={_hotel}
                  type={"hotel"}
                />
              </Col>
            </Row>
          </>
        </StyledContainer>
      ) : null}

      {features !== null && renderedFeatures.length ? (
        <StyledContainer
          className={`bg-light pt-4 pt-lg-5  position-relative ${
            isWebFramed ? "mb-4 mb-lg-5 pb-4 pb-lg-5" : ""
          }`}
          fluid={true}
        >
          <>
            <a
              className="anchor-target visually-hidden"
              id="hoteldetails"
              aria-hidden={true}
            />
            <StyledContainer fluid="sm">
              <Row>
                <Col className="text-center text-lg-start featureCol">
                  <div id={"about-the-hotel"} className="subTitle">
                    About The Hotel
                  </div>
                  {/* <div id="hotel-details-title" className="title">Hotel Details</div> */}
                  <Title2 id="hotel-details-title">Hotel Details </Title2>
                </Col>
              </Row>
              <FeaturesTabs
                features={renderedFeatures}
                gallery={null}
                hotel={_hotel}
                location={_location}
              />
            </StyledContainer>
          </>
        </StyledContainer>
      ) : null}

      {_fieldBody && <StyledContainer>{_fieldBody}</StyledContainer>}
      {!isWebFramed && (
        <HotelPageBreadCrumb BreadcrumbDetails={BreadcrumbDetails} />
      )}
      <Modal
        size="lg"
        show={showModalFeature}
        onHide={() => setShowModalFeature(false)}
        centered
        scrollable={true}
        aria-labelledby="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={modalContent} width="100%" height="500" />
        </Modal.Body>
      </Modal>
    </Layout>
  );
};
export const query = graphql`
  query ($slug: String!, $pathRegex: String) {
    hotel(path: { alias: { eq: $slug } }) {
      name
      description
      field_hotel_meta_description
      field_meta_t
      field_cvent
      field_video_url {
        url
      }
      field_check_in
      field_check_out
      field_default_adults
      field_default_lead_days
      field_lead_start_date
      field_lead_days_settings
      field_default_length_of_stay
      address {
        address_line1
        locality
        administrative_area
        postal_code
        country_code
      }
      lat_lon {
        lat
        lon
      }
      path {
        alias
      }
      crs_code
      crs_name
      crs_chain_id
      hotel_code
      phone
      field_googl
      features {
        tab_title
        title
        description
        video_url
        image {
          url
          alt
        }
        sub_features {
          tab_title
          title
          description
          video_url
          image {
            url
            alt
          }
        }
      }
      gallery_images {
        url
        alt
      }
      banner_images {
        url
        alt
      }
      amenities {
        name
        machine_name
      }
      relationships {
        field_sections {
          type: __typename
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
        }
        hotelAwards: field_hotel_awards {
          id
          field_award_caption
          field_award_title
          relationships {
            field_award_image {
              relationships {
                field_media_image {
                  publicUrl
                  gatsbyImage(
                    width: 200
                    height: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
        desktopImage: field_banner_images_n {
          relationships {
            field_media_image {
              publicUrl
              gatsbyImage(
                width: 1920
                height: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mobileImage: field_banner_images_n {
          relationships {
            field_media_image {
              publicUrl
              gatsbyImage(
                width: 490
                height: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        brand_id {
          name
          brand_code
          description {
            summary
            processed
            value
          }
          path {
            alias
          }
          field_brand_stripe_color {
            color
          }
          field_color {
            color
          }
          field_fore_color {
            color
          }
          field_brandbars_bg_color {
            color
          }
          field_brandbars_fore_color {
            color
          }
          field_urgency_msg_bg_color {
            color
          }
          field_urgency_msg_fore_color {
            color
          }
          relationships {
            field_logo {
              publicUrl
            }
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
            }
          }
        }
        field_page_message {
          id
          field_description
        }
        offers: field_offers {
          type: __typename
          ...ParagraphFeaturedOffers
        }
      }
    }
    page: nodePage(title: { eq: "Hotel Search" }) {
      id
      field_show_unlock_banner
      field_show_special_offers
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphMediaCta
        }
      }
    }
    allLocationCity(
      filter: { fields: { path: { alias: { regex: $pathRegex } } } }
    ) {
      nodes {
        name
        fields {
          path {
            alias
            parent_alias
          }
        }
        relationships {
          field_sites {
            machine_name
          }
        }
      }
    }
  }
`;
export default HotelPage;
